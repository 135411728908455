import { type BuildColumns, relations } from "drizzle-orm";
import {
  type PgColumnBuilderBase,
  type PgTableExtraConfig,
  boolean,
  index,
  integer,
  jsonb,
  pgTable,
  primaryKey,
  text,
  timestamp,
  unique,
  uuid,
} from "drizzle-orm/pg-core";
import type { AdapterAccount } from "next-auth/adapters";

export const workspaces = pgTable("workspace", {
  id: uuid("id").defaultRandom().primaryKey(),
  name: text("name").notNull(),
});

export const workspacesRelations = relations(workspaces, ({ many }) => ({
  members: many(workspace_members),
  uploader_users: many(uploader_users),
}));

export enum WorkspaceRole {
  /** Viewer can view videos */
  Viewer = "viewer",
  /** IT person can view tokens and setup users */
  ITSupport = "it_support",
  /** Admin can manage workspace membership and update uploader user auth tokens  */
  Admin = "admin",
}

export const workspaceRoleTitles = {
  [WorkspaceRole.Admin]: "Admin",
  [WorkspaceRole.Viewer]: "Viewer",
  [WorkspaceRole.ITSupport]: "IT Support",
};

export const workspace_members = pgTable(
  "workspace_member",
  {
    workspace_id: uuid("workspace_id")
      .notNull()
      .references(() => workspaces.id, {
        onDelete: "cascade",
      }),
    user_id: text("user_id")
      .notNull()
      .references(() => users.id, {
        onDelete: "cascade",
      }),
    role: text("role").$type<WorkspaceRole>().notNull(),
  },
  (workspace_user) => ({
    uniq: unique().on(workspace_user.workspace_id, workspace_user.user_id),
    workspace_idx: index("workspace_idx").on(workspace_user.workspace_id),
    user_idx: index("user_idx").on(workspace_user.user_id),
  }),
);

export const workspaceMembersRelation = relations(
  workspace_members,
  ({ one }) => ({
    workspace: one(workspaces, {
      fields: [workspace_members.workspace_id],
      references: [workspaces.id],
    }),
    user: one(users, {
      fields: [workspace_members.user_id],
      references: [users.id],
    }),
  }),
);

const workspace_columns = {
  workspace_id: uuid("workspace_id")
    .notNull()
    .references(() => workspaces.id, {
      onDelete: "cascade",
    }),
} as const;

const pgWorkspaceScopedTable = <
  TTableName extends string,
  TColumnsMap extends Record<string, PgColumnBuilderBase>,
>(
  name: TTableName,
  columns: TColumnsMap,
  extraConfig?: (
    self: BuildColumns<
      TTableName,
      TColumnsMap & typeof workspace_columns,
      "pg"
    >,
  ) => PgTableExtraConfig,
) => {
  return pgTable(
    name,
    {
      ...columns,
      ...workspace_columns,
    },
    (t) => {
      const config =
        typeof extraConfig === "undefined" ? {} : extraConfig(t as any);
      return {
        ...config,
        workspace_idx: index(`${name}_workspace_idx`).on(t.workspace_id),
      };
    },
  );
};

export const uploader_users = pgWorkspaceScopedTable(
  "uploader_user",
  {
    id: uuid("id").defaultRandom().primaryKey(),
    name: text("name").notNull(),
    // TODO consider hashing this
    auth_token: uuid("auth_token").defaultRandom().notNull().unique(),

    // TODO delete and never talk about this again
    login_filepath_prefix: text("login_filepath_prefix"),

    created_at: timestamp("created_at").notNull().defaultNow(),
    last_used_at: timestamp("last_used_at"),
  },
  (t) => ({
    auth_token_idx: index("uploader_user_auth_token_idx").on(t.auth_token),
  }),
);

export const uploaderUsersRelations = relations(uploader_users, ({ one }) => ({
  workspace: one(workspaces, {
    fields: [uploader_users.workspace_id],
    references: [workspaces.id],
  }),
}));

export const userUploads = pgWorkspaceScopedTable(
  "user_upload",
  {
    id: uuid("id").defaultRandom().primaryKey(),

    uploader_user_id: uuid("uploader_user_id")
      .notNull()
      .references(() => uploader_users.id, { onDelete: "cascade" }),

    // non-unique. Used for deduping on the videoplayer if there are multiple
    // overlapping uploads, ie. briefly during rotation. Also to track screens
    // across rotations.
    screen_id: text("screen_id").notNull(),

    // used to approximate how many users are using a given version at any
    // given time
    uploader_version: text("uploader_version").notNull(),

    // time ranges butchered by postgres timestamptz not storing the
    // timezone the timestamp was taken in. To query the local time,
    // we'll store it separately from the utc time. By storing a
    // duration, we can get the end_ts in either the local or utc
    // timezone, with start_ts_utc + duration or
    // start_ts_lcl + duration, as we please.
    start_ts_utc: timestamp("start_ts_utc", { withTimezone: true }).notNull(),
    start_ts_lcl: timestamp("start_ts_lcl", { withTimezone: false }).notNull(),
    duration_ms: integer("duration_ms"),

    file_type: text("file_type").notNull(),
    s3_bucket: text("s3_bucket").notNull(),
    s3_key: text("s3_key").notNull(),
    s3_region: text("s3_region").notNull(),
    multipart_upload_id: text("multipart_upload_id").notNull(),

    created_at: timestamp("created_at").notNull().defaultNow(),
  },
  (t) => ({
    // not unique, because the seed db needs multiple entries for "seed-demo.mov"
    // unique: unique().on(t.s3_bucket, t.s3_key, t.s3_region),

    // used by steno page
    date_idx: index("date_idx").on(
      t.workspace_id,
      t.uploader_user_id,
      t.start_ts_lcl,
    ),
  }),
);

export const cases = pgWorkspaceScopedTable("case", {
  id: uuid("id").defaultRandom().primaryKey(),
  description: text("description").notNull(),
});

export const caseRelations = relations(cases, ({ many }) => ({
  tasks: many(tasks),
  case_tags: many(case_tags),
}));

export const case_tags = pgWorkspaceScopedTable(
  "case_tag",
  {
    case_id: uuid("case_id")
      .notNull()
      .references(() => cases.id, {
        onDelete: "cascade",
      }),
    tag_id: uuid("tag_id")
      .notNull()
      .references(() => tags.id, { onDelete: "cascade" }),
  },
  (case_tag) => ({
    uniq: unique().on(case_tag.case_id, case_tag.tag_id),
    case_idx: index("case_tag_case_idx").on(case_tag.case_id),
    tag_idx: index("case_tag_tag_idx").on(case_tag.tag_id),
  }),
);

export const caseTagsRelation = relations(case_tags, ({ one }) => ({
  cases: one(cases, {
    fields: [case_tags.case_id],
    references: [cases.id],
  }),
  tags: one(tags, {
    fields: [case_tags.tag_id],
    references: [tags.id],
  }),
}));

export const tasks = pgWorkspaceScopedTable(
  "task",
  {
    id: uuid("id").defaultRandom().primaryKey().notNull(),
    uploader_user_id: uuid("uploader_user_id")
      .notNull()
      .references(() => uploader_users.id, {
        onDelete: "cascade",
      }),
    case_id: uuid("case_id").references(() => cases.id, {
      onDelete: "cascade",
    }),
    description: text("description"),
    start_ts: timestamp("start_ts").notNull(),
    end_ts: timestamp("end_ts").notNull(),
  },
  (task) => ({
    // used by steno page
    query_idx: index("task_query_idx").on(
      task.workspace_id,
      task.uploader_user_id,
      task.start_ts,
    ),
  }),
);

export const tasksRelations = relations(tasks, ({ one, many }) => ({
  case: one(cases, {
    fields: [tasks.case_id],
    references: [cases.id],
  }),
  task_tags: many(task_tags),
}));

export const structured_data_spans = pgWorkspaceScopedTable(
  "structured_data_span",
  {
    id: uuid("id").defaultRandom().primaryKey().notNull(),
    uploader_user_id: uuid("uploader_user_id")
      .notNull()
      .references(() => uploader_users.id, {
        onDelete: "cascade",
      }),
    data_key: text("data_key").notNull(),

    // time ranges butchered by postgres timestamptz not storing the
    // timezone the timestamp was taken in. To query the local time,
    // we'll store it separately from the utc time. By storing a
    // duration, we can get the end_ts in either the local or utc
    // timezone, with start_ts_utc + duration or
    // start_ts_lcl + duration, as we please.
    start_ts_utc: timestamp("start_ts_utc", { withTimezone: true }).notNull(),
    start_ts_lcl: timestamp("start_ts_lcl", { withTimezone: false }).notNull(),
    duration_ms: integer("duration_ms"),

    extra_data: jsonb("metadata"),
  },
  (task) => ({
    // used by steno page
    query_idx: index("structured_data_span_query_idx").on(
      task.workspace_id,
      task.uploader_user_id,
      task.data_key,
      task.start_ts_lcl,
    ),
  }),
);

export const structured_data_span_relations = relations(
  structured_data_spans,
  ({ one }) => ({
    uploader_user: one(uploader_users, {
      fields: [structured_data_spans.uploader_user_id],
      references: [uploader_users.id],
    }),
  }),
);

export const task_tags = pgWorkspaceScopedTable(
  "task_tag",
  {
    task_id: uuid("task_id")
      .notNull()
      .references(() => tasks.id, {
        onDelete: "cascade",
      }),
    tag_id: uuid("tag_id")
      .notNull()
      .references(() => tags.id, { onDelete: "cascade" }),
  },
  (task_tag) => ({
    uniq: unique().on(task_tag.workspace_id, task_tag.task_id, task_tag.tag_id),
    task_idx: index("task_tag_task_idx").on(task_tag.task_id),
    tag_idx: index("task_tag_tag_idx").on(task_tag.tag_id),
  }),
);

export const taskTagsRelation = relations(task_tags, ({ one }) => ({
  task: one(tasks, {
    fields: [task_tags.task_id],
    references: [tasks.id],
  }),
  tag: one(tags, {
    fields: [task_tags.tag_id],
    references: [tags.id],
  }),
}));

export const tags = pgWorkspaceScopedTable(
  "tag",
  {
    id: uuid("id").defaultRandom().notNull().primaryKey(),
    name: text("tag").notNull(),
    description: text("description").notNull(),
    color: text("color").notNull(),
  },
  (tag) => ({
    tag_idx: index("tag_tag_idx").on(tag.workspace_id, tag.name),
    unique: unique().on(tag.workspace_id, tag.name),
  }),
);

export const tagRelations = relations(tags, ({ many }) => ({
  case_tags: many(case_tags),
  task_tags: many(task_tags),
}));

/** Actions for audit log. Changing these values will require a database migration */
export enum AuditLogAction {
  // TODO figure out desktop audit log
  // DesktopConnect = "desktop.connect",
  // DesktopDisconnect = "desktop.disconnect",
  // DesktopRegister = "desktop.register",
  WebAgentTokenReset = "web.agent_token.reset",
  WebAgentTokenView = "web.agent_token.view",
  WebAgentDelete = "web.agent.delete",
  WebAgentRegister = "web.agent.register",
  WebLogin = "web.login",
  WebPlayback = "web.playback",
  WebWorkspaceMemberAdd = "web.workspace.member.add",
  WebWorkspaceMemberUpdate = "web.workspace.member.update",
  WebWorkspaceMemberDelete = "web.workspace.member.delete",
}

// TODO map types

export interface WebPlaybackMetadata {
  begin_ts: number;
  end_ts?: number;
  uploader_user_id: string;
  uploader_user_name: string;
}

export interface WebLoginMetadata {
  provider: "azure-ad" | "email";
}

export interface WebAgentTokenMetadata {
  uploader_user_id: string;
  uploader_user_name: string;
}

export interface WebAgentDeleteMetadata {
  uploader_user_id: string;
  uploader_user_name: string;
}

export interface WebAgentRegisterMetadata {
  filePath: string;
  userName: string;
  userDir: string;
  computerName: string;
  uploader_user_id: string;
  uploader_user_name: string;
}

export interface WebWorkspaceMemberAddMetadata {
  member_id: string;
  member_name: string;
  member_email: string;
  new_role: WorkspaceRole;
}

export interface WebWorkspaceMemberUpdateMetadata {
  member_id: string;
  member_name: string;
  member_email: string;
  new_role: WorkspaceRole;
}

export interface WebWorkspaceMemberDeleteMetadata {
  member_id: string;
  member_name: string;
  member_email: string;
  old_role: WorkspaceRole;
}

export type AuditLogMetadata =
  | WebLoginMetadata
  | WebPlaybackMetadata
  | WebAgentTokenMetadata
  | WebAgentDeleteMetadata
  | WebAgentRegisterMetadata
  | WebWorkspaceMemberAddMetadata
  | WebWorkspaceMemberUpdateMetadata
  | WebWorkspaceMemberDeleteMetadata;

export const auditLog = pgWorkspaceScopedTable(
  "audit_log",
  {
    id: uuid("id").defaultRandom().notNull().primaryKey(),
    ts: timestamp("ts", { withTimezone: true }).notNull().defaultNow(),
    user_id: text("user_id").references(() => users.id, {
      onDelete: "set null",
    }),

    action: text("action").$type<AuditLogAction>().notNull(),
    metadata: jsonb("metadata"),
  },
  (t) => ({
    query_idx: index("audit_log_query_idx").on(
      t.workspace_id,
      t.ts,
      t.user_id,
      t.action,
    ),
  }),
);

export const auditLogRelations = relations(auditLog, ({ one }) => ({
  users: one(users, {
    fields: [auditLog.user_id],
    references: [users.id],
  }),
}));

export const users = pgTable("user", {
  id: text("id")
    .primaryKey()
    .notNull()
    .$defaultFn(() => crypto.randomUUID()),
  name: text("name"),
  email: text("email").notNull(),
  emailVerified: timestamp("email_verified", { mode: "date" }),
  image: text("image"),
});

export const accounts = pgTable(
  "account",
  {
    userId: text("user_id")
      .notNull()
      .references(() => users.id, { onDelete: "cascade" }),
    type: text("type").$type<AdapterAccount["type"]>().notNull(),
    provider: text("provider").notNull(),
    providerAccountId: text("provider_account_id").notNull(),
    refresh_token: text("refresh_token"),
    access_token: text("access_token"),
    expires_at: integer("expires_at"),
    token_type: text("token_type"),
    scope: text("scope"),
    id_token: text("id_token"),
    session_state: text("session_state"),
  },
  (account) => ({
    compoundKey: primaryKey({
      name: "account_pk",
      columns: [account.provider, account.providerAccountId],
    }),
  }),
);

export const sessions = pgTable("session", {
  sessionToken: text("session_token").primaryKey(),
  userId: text("user_id")
    .notNull()
    .references(() => users.id, { onDelete: "cascade" }),
  expires: timestamp("expires", { mode: "date" }).notNull(),
});

export const verificationTokens = pgTable(
  "verification_token",
  {
    identifier: text("identifier").notNull(),
    token: text("token").notNull(),
    expires: timestamp("expires", { mode: "date" }).notNull(),
  },
  (verificationToken) => ({
    compositePk: primaryKey({
      name: "verification_token_pk",
      columns: [verificationToken.identifier, verificationToken.token],
    }),
  }),
);

export const authenticators = pgTable(
  "authenticator",
  {
    credentialID: text("credential_id").notNull().unique(),
    userId: text("user_id")
      .notNull()
      .references(() => users.id, { onDelete: "cascade" }),
    providerAccountId: text("provider_account_id").notNull(),
    credentialPublicKey: text("credential_public_key").notNull(),
    counter: integer("counter").notNull(),
    credentialDeviceType: text("credential_device_type").notNull(),
    credentialBackedUp: boolean("credential_backed_up").notNull(),
    transports: text("transports"),
  },
  (authenticator) => ({
    compositePK: primaryKey({
      name: "authenticator_pk",
      columns: [authenticator.userId, authenticator.credentialID],
    }),
  }),
);
